<template>
    <div class="page-create-new-password">
        <app-branding-logo v-if="!withoutBranding" />

        <form>
            <h2 class="heading">Create new password</h2>

            <app-loader v-if="loading" />

            <app-error v-model="errors.show" :message="errors.message" />

            <app-input
                v-model="new_password"

                type="password"

                label="New password"

                :error="errors.fields.new_password"

                :password-helper="true"
                :with-toggle-password-visibility="true"

                ref="new-password"

                @toggle-password-visibility="onTogglePasswordVisibility"
            />

            <app-input
                v-model="confirm_new_password"

                type="password"

                label="Confirm new password"

                :error="errors.fields.confirm_new_password"

                :with-toggle-password-visibility="true"

                ref="confirm-new-password"

                @toggle-password-visibility="onTogglePasswordVisibility"
            />

            <div class="actions">
                <button class="btn btn-primary btn-create" @click.prevent="createNewPassword">Create</button>
            </div>
        </form>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appError from '@/components/app-error'
import appLoader from '@/components/app-loader'
import appInput from '@/components/app-input'
import appBrandingLogo from '@/components/app-branding-logo'

import UserDetailsValidator from '@/validators/user-details-validator'
import errMessage from '@/helpers/errMessage'

const SPID = 0

export default {
    components: {
        appError,
        appLoader,
        appInput,
        appBrandingLogo,
    },

    props: {
        withoutBranding: { type: Boolean, default: true }
    },

    data() {
        return {
            new_password: '',
            confirm_new_password: '',

            loading: false,

            errors: {
                show: false,
                message: '',

                fields: {},
            },
        }
    },

    created() {
        this.verifyToken()
    },

    methods: {
        onTogglePasswordVisibility(password_visibility) {
            this.$refs['new-password'].setPasswordVisibility(password_visibility)
            this.$refs['confirm-new-password'].setPasswordVisibility(password_visibility)
        },

        verifyToken() {
            this.loading = true

            const params = {
                SPID: SPID,
                Token: this.token,
            }

            this.$store.dispatch('api_auth/ResetPasswordStep2LocalUser', params)
                .then(() => {
                    this.loading = false
                })
                .catch(error => {
                    this.loading = false

                    this.$store.dispatch('addToast', {
                        message: errMessage(error),
                        type: 'error',
                        delay: 5000,
                    })

                    this.$router.push(this.sign_in_link)
                })
        },

        validation() {
            let is_valid = true

            this.errors.fields = {}

            const fields = {
                new_password:         { rule: 'passnew', message: 'Please, enter new password'                 },
                confirm_new_password: { rule: 'passnew', message: 'Please, enter confirmation of new password' },
            }

            for (const key in fields) {
                if (UserDetailsValidator.isRuleExists(fields[key].rule)) {
                    if (UserDetailsValidator.isInvalid(fields[key].rule, this[key], fields[key].message)) {
                        this.errors.fields[key] = UserDetailsValidator.getError()
                        is_valid = false
                    }
                }
            }

            if (!this.errors.fields.confirm_new_password) {
                if (this.new_password != this.confirm_new_password) {
                    this.errors.fields.confirm_new_password = 'New password and confirmation password do not match'
                    is_valid = false
                }
            }

            return is_valid
        },

        createNewPassword() {
            if (this.validation()) {
                this.loading = true

                const params = {
                    SPID: SPID,
                    Token: this.token,
                    NewPassword: this.new_password,
                }

                this.$store.dispatch('api_auth/ResetPasswordStep3LocalUser', params)
                    .then(() => {
                        this.errors.show = false
                        this.errors.message = ''

                        this.loading = false

                        this.$store.dispatch('addToast', {
                            message: 'Your password has been reset successfully',
                            type: 'success',
                            delay: 5000,
                        })

                        this.$router.push(this.sign_in_link)
                    })
                    .catch(error => {
                        this.errors.show = true
                        this.errors.message = errMessage(error)

                        this.loading = false
                    })
            }
        },
    },

    computed: {
        ...mapGetters([
            'reset_password_redirect_host',
        ]),

        token() {
            return this.$route.params.token
        },

        sign_in_link() {
            return {
                name: 'sign-in',
                ...(this.withoutBranding ? {} : { query: { branding: 'true' } }),
            }
        },
    },
}
</script>

<style lang="scss">
.page-create-new-password {
    .app-error {
        margin-bottom: 24px;
    }
}
</style>